<template>
  <div class="guide">
    <div class="guide-head">
      <img class="icon-logo" src="../assets/img/logo.png" />
      <div
        style="display: flex; align-items: center"
        @click="languageShow = true"
      >
        <div
          class="flag"
          :class="
            language == '简体中文'
              ? 'flag_CHN'
              : language == 'English'
              ? 'flag_USA'
              : 'flag_VNM'
          "
        ></div>
        {{ $t("语言") }}
      </div>
    </div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img class="swipe-img" src="../assets/img/banner-bg.jpg"
      /></van-swipe-item>
      <!-- <van-swipe-item
        ><img class="swipe-img" src="../assets/img/banner-bg-cp.jpg"
      /></van-swipe-item>
      <van-swipe-item
        ><img class="swipe-img" src="../assets/img/banner-bg05.jpg"
      /></van-swipe-item> -->
    </van-swipe>
    <div class="guide-body">
      <div class="guide-body-title">{{ $t("home-introduce-title") }}</div>
      <div class="guide-body-brief">{{ $t("home-introduce-content") }}</div>
      <img class="guide-body-img" src="../assets/img/index-temp.png" />
    </div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <div class="guide-swipe-item">
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/offer.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-findsup") }}
            </div>
          </div>
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/contract.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-inquiry") }}
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="guide-swipe-item">
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/credit.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-sample") }}
            </div>
          </div>
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/booking.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-order") }}
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="guide-swipe-item">
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/docu.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-supman") }}
            </div>
          </div>
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/logistics.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-market") }}
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="guide-swipe-item">
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/money.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-platservice") }}
            </div>
          </div>
          <div class="guide-swipe-block">
            <img class="guide-swipe-img" src="../assets/img/total.jpg" />
            <div class="guide-swipe-mask"></div>
            <div class="guide-swipe-title">
              {{ $t("home-process-statistical") }}
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="guide-foot">
      <div class="guide-foot-list">
        {{ $t("contact_tel") }}：0086-13666976366，0084-937368358 
      </div>
      <div class="guide-foot-list">
        {{ $t("邮箱地址") }}：service01@ihoong.com
      </div>
      <div class="guide-foot-list">{{ $t("企业名称") }}：Ihoong Inc</div>
      <div class="guide-foot-list">
        {{ $t("Address") }}：Floor 26th 7# No.7 building HongKong city Chengdong
        St. Fengze Dist Quahzhou City Fujian,China
      </div>
      <div class="guide-foot-list">
        <a
          class="guide-foot-a"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >闽ICP备20001592号</a
        >
      </div>
    </div>
    <van-action-sheet
      v-model="languageShow"
      :actions="columns"
      @select="languageConfirm"
    />
  </div>
</template>
<script>
export default {
  name: "Guide",
  data() {
    return {
      show: false,
      language: "",
      columns: [
        { name: "简体中文", value: "zh-cn" },
        { name: "English", value: "en-us" },
        { name: "ViệtName", value: "vi" },
      ],
      languageShow: false,
    };
  },
  mounted() {
    this.language = this.columns.find(
      (item) => item.value == this.$i18n.locale
    ).name;
  },
  methods: {
    languageConfirm(item) {
      // 语言确认事件
      this.language = this.columns.find((res) => res.value == item.value).name;
      this.languageShow = false;
      localStorage.setItem("lang", item.value);
      this.$http
        .post("/v1/getLang", {
          lang: item.value,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$i18n.setLocaleMessage(item.value, JSON.parse(res.data));
            this.$i18n.locale = item.value;
          }
        });
    },
  },
};
</script>
<style scoped>
.guide {
  padding-bottom: 50px;
}
.guide-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5vw;
}
.icon-logo {
  height: 40px;
  width: auto;
}
.icon-menu {
  width: 20px;
  height: 20px;
}
.van-cell {
  display: flex;
  align-items: center;
}
.flag_CHN {
  background: no-repeat url(../assets/img/nationalflag.jpg) 0 -187px;
  background-size: auto;
  width: 18px;
  height: 13px;
  margin-right: 5px;
}
.flag_USA {
  background: no-repeat url(../assets/img/nationalflag.jpg) 0 -2311px;
  background-size: auto;
  width: 18px;
  height: 13px;
  margin-right: 5px;
}
.flag_VNM {
  background: no-repeat url(../assets/img/nationalflag.jpg) 0 -2056px;
  background-size: auto;
  width: 18px;
  height: 13px;
  margin-right: 5px;
}
.swipe-img {
  width: 90vw;
  height: auto;
}
.guide-body {
  padding: 0 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 20px;
  background-color: white;
}
.guide-body-title {
  font-size: 18px;
  font-weight: 500;
  color: #3e464c;
  padding: 10px 0;
}
.guide-body-brief {
  font-size: 12px;
  font-weight: 400;
  color: #68747f;
}

.guide-body-img {
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
}

.my-swipe {
  padding: 0 5vw;
}

.guide-foot {
  margin-top: 20px;
  font-size: 12px;
  background: #2b2f33;
  padding: calc(5vw - 10px) 5vw 5vw;
  color: white;
}

.guide-foot-list {
  margin-top: 10px;
}

.guide-foot-a {
  color: white;
  text-align: center;
  display: block;
  margin: auto;
  margin-top: 10px;
}

.guide-swipe-item {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.guide-swipe-block {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.guide-swipe-img {
  width: 42.5vw;
  height: 59.5vw;
}
.guide-swipe-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(30, 42, 68, 0.7);
}
.guide-swipe-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 10px;
  text-align: center;
  white-space: pre-wrap;
  opacity: 0.55;
  font-size: 16px;
}
</style>
